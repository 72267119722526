// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-license_InIog{display:flex;flex-wrap:wrap;gap:8px 16px;align-items:center;justify-content:center}.footer-license__item_N4GXl{margin:0}.footer-license__item--link_jMIDg{cursor:pointer}.footer-license__item--filter_X3F7f{filter:var(--FooterIconFilter)}.footer-license__item--crc_wSfzi{width:32px;height:34px;overflow:hidden}html.app__layout--desktop .footer-license--landing_Lwn6X{gap:8px 46px;padding:32px 0}.footer-license--panel_ZleVp{padding:16px;background-color:var(--OpacityLayer1);border-radius:var(--BorderRadius)}html.app__layout--desktop .footer-license--panel_ZleVp{padding:24px 16px}.footer-license--grayscale_d6wGs .footer-license__item_N4GXl{mix-blend-mode:luminosity}.footer-license--grayscale_d6wGs .footer-license__item--colored_izbtR{mix-blend-mode:normal}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer-license": `footer-license_InIog`,
	"footer-license__item": `footer-license__item_N4GXl`,
	"footer-license__item--link": `footer-license__item--link_jMIDg`,
	"footer-license__item--filter": `footer-license__item--filter_X3F7f`,
	"footer-license__item--crc": `footer-license__item--crc_wSfzi`,
	"footer-license--landing": `footer-license--landing_Lwn6X`,
	"footer-license--panel": `footer-license--panel_ZleVp`,
	"footer-license--grayscale": `footer-license--grayscale_d6wGs`,
	"footer-license__item--colored": `footer-license__item--colored_izbtR`
};
export default ___CSS_LOADER_EXPORT___;
