// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-buttons_V6PlZ{display:flex}.app-buttons--all_fEN1Y{flex-direction:column}.app-buttons__buttons_mBhKR{display:flex}.app-buttons__item_Mo0Pw{width:64px}.app-buttons__item_Mo0Pw:first-child{margin-right:8px}.app-buttons__text_jfAlQ{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;display:flex;align-items:center;color:var(--TextPrimary)}.app-buttons--all_fEN1Y .app-buttons__text_jfAlQ{margin-top:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app-buttons": `app-buttons_V6PlZ`,
	"app-buttons--all": `app-buttons--all_fEN1Y`,
	"app-buttons__buttons": `app-buttons__buttons_mBhKR`,
	"app-buttons__item": `app-buttons__item_Mo0Pw`,
	"app-buttons__text": `app-buttons__text_jfAlQ`
};
export default ___CSS_LOADER_EXPORT___;
