// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content_VOCb1{margin-top:-8px;margin-right:4px;margin-left:4px}.cms-content_DaiFW,.content_VOCb1{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px}.cms-content_DaiFW{padding:0 4px}html.app__layout--desktop .cms-content_DaiFW{padding:0 4px}html.app__layout--desktop .balance-modal_kp27r{z-index:69;position:fixed;top:0;right:0;bottom:0;left:0}html.app__layout--desktop .balance-modal__wrapper_HGgAV{z-index:1;top:58px;left:calc(50% - 172px);width:344px;position:relative;display:flex;flex-direction:column;max-height:calc(100vh - 74px);border-radius:15px;box-shadow:0 8px 16px #0000004d;will-change:transform}html.app__layout--desktop .balance-modal__header_p3yaJ{border-radius:15px 15px 0 0}html.app__layout--desktop .balance-modal__inner_OLkmI{padding:0 16px 16px}html.app__layout--desktop .balance-modal__scrollbar_NvWty{display:flex;flex:1;flex-direction:column;overflow:auto;background-color:var(--Layer0);border-radius:0 0 15px 15px}html.app__layout--desktop .balance-modal__balances_RRaqd{margin-bottom:8px}html.app__layout--desktop .balance-modal__withdrawal-request_ZTxDD{margin-bottom:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `content_VOCb1`,
	"cms-content": `cms-content_DaiFW`,
	"balance-modal": `balance-modal_kp27r`,
	"balance-modal__wrapper": `balance-modal__wrapper_HGgAV`,
	"balance-modal__header": `balance-modal__header_p3yaJ`,
	"balance-modal__inner": `balance-modal__inner_OLkmI`,
	"balance-modal__scrollbar": `balance-modal__scrollbar_NvWty`,
	"balance-modal__balances": `balance-modal__balances_RRaqd`,
	"balance-modal__withdrawal-request": `balance-modal__withdrawal-request_ZTxDD`
};
export default ___CSS_LOADER_EXPORT___;
