// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.horizontal-navigation-button_w76ao{font-size:12px;font-weight:500;line-height:16px;text-transform:uppercase;letter-spacing:.4px;position:relative;display:flex;column-gap:4px;align-items:center;height:56px;padding:20px 12px;margin:0 0;color:var(--DTextPrimary);-webkit-text-decoration:none;text-decoration:none;white-space:nowrap;-webkit-appearance:none;appearance:none;-webkit-user-select:none;user-select:none;border-radius:0}@media(min-width:1280px){.horizontal-navigation-button_w76ao{height:56px}}.horizontal-navigation-button--active_LfyiW{color:var(--DTextDefault);background-color:var(--DLayer2)}.horizontal-navigation-button_w76ao:hover{background-color:var(--DHighlight)}.horizontal-navigation-button--inverted_idADP{color:var(--DTextPrimary);background-color:var(--DLayer2)}.horizontal-navigation-button--inverted_idADP:hover{color:var(--DTextDefault);background-color:var(--DHighlight)}.horizontal-navigation-button--with-image_FTA7y{column-gap:8px}.horizontal-navigation-button--landing_scYEP{color:red}@media(hover:hover)and (pointer:fine){.horizontal-navigation-button--landing_scYEP:hover{color:red}}.horizontal-navigation-button--promo-highlighted_ybw1s .horizontal-navigation-button__icon_GWZu5{fill:var(--PinkHighLight)}.horizontal-navigation-button__icon--landing_e8KyP{width:20px;height:20px;border-radius:50%;fill:var(--DTextPrimary)}.horizontal-navigation-button__badge_DJbw2{margin-left:4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"horizontal-navigation-button": `horizontal-navigation-button_w76ao`,
	"horizontal-navigation-button--active": `horizontal-navigation-button--active_LfyiW`,
	"horizontal-navigation-button--inverted": `horizontal-navigation-button--inverted_idADP`,
	"horizontal-navigation-button--with-image": `horizontal-navigation-button--with-image_FTA7y`,
	"horizontal-navigation-button--landing": `horizontal-navigation-button--landing_scYEP`,
	"horizontal-navigation-button--promo-highlighted": `horizontal-navigation-button--promo-highlighted_ybw1s`,
	"horizontal-navigation-button__icon": `horizontal-navigation-button__icon_GWZu5`,
	"horizontal-navigation-button__icon--landing": `horizontal-navigation-button__icon--landing_e8KyP`,
	"horizontal-navigation-button__badge": `horizontal-navigation-button__badge_DJbw2`
};
export default ___CSS_LOADER_EXPORT___;
