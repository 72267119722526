// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-profile_XX_wA{display:flex;gap:12px;align-items:center;padding:0;margin:0;list-style:none}@media(min-width:768px){.header-profile_XX_wA{gap:16px}}.header-profile__item_SLEJp{position:relative;display:flex}.header-profile__item--balance_cpbYB,.header-profile__item--balance_cpbYB:hover{background-color:var(--DLayer2)}.header-profile__item-badge_my5II{z-index:1;position:absolute;top:8px;right:8px;width:5px;height:5px;background-color:var(--AlertDefault);border-radius:50%}.header-profile__item-badge--danger_YsqHx{background-color:var(--ErrorDefault)}html.app__layout--desktop .header-profile_XX_wA{padding-left:6px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-profile": `header-profile_XX_wA`,
	"header-profile__item": `header-profile__item_SLEJp`,
	"header-profile__item--balance": `header-profile__item--balance_cpbYB`,
	"header-profile__item-badge": `header-profile__item-badge_my5II`,
	"header-profile__item-badge--danger": `header-profile__item-badge--danger_YsqHx`
};
export default ___CSS_LOADER_EXPORT___;
