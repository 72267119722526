import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
import HeaderNavigationButton from './components/HeaderNaviagtionButton/HeaderNavigationButton.vue';
import { useHeaderNavigation } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeaderNavigation',
    props: {
        navigationItems: {
            default: ()=>[]
        },
        balanceValue: {
            default: ''
        },
        isLogged: {
            type: Boolean
        },
        isPromotionsHighlighted: {
            type: Boolean
        },
        isLandingFestivalEnabled: {
            type: Boolean
        },
        menuOverflowHidden: {
            type: Boolean,
            default: true
        }
    },
    emits: [
        "set-parent-overflow-visible",
        "sportline-navigation"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const properties = __props;
        const emit = __emit;
        const { container, baseList, navigationList, dropList, handleClick } = useHeaderNavigation(properties, emit);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("nav", {
                ref_key: "container",
                ref: container,
                class: _normalizeClass({
                    [_ctx.$style['horizontal-navigation']]: true,
                    [_ctx.$style['horizontal-navigation--hide-overflow']]: _ctx.menuOverflowHidden
                })
            }, [
                _createElementVNode("ul", {
                    ref_key: "baseList",
                    ref: baseList,
                    class: _normalizeClass(_ctx.$style['horizontal-navigation__base'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(navigationList), (param)=>{
                        let { id, routeName, props, icon, iconCdnSrc, ...buttonProps } = param;
                        return _openBlock(), _createElementBlock("li", {
                            key: id,
                            class: _normalizeClass(_ctx.$style['horizontal-navigation__list-item'])
                        }, [
                            _withDirectives(_createVNode(HeaderNavigationButton, _mergeProps({
                                ref_for: true
                            }, buttonProps, {
                                class: id,
                                icon: icon,
                                "icon-cdn-src": iconCdnSrc,
                                "is-promotions-highlighted": !!(props && props.isPromo && _ctx.isPromotionsHighlighted),
                                "is-landing-euro": !!(props && props.isLandingEuro && _ctx.isLandingFestivalEnabled),
                                onClick: ($event)=>_unref(handleClick)({
                                        id,
                                        routeName,
                                        props
                                    })
                            }), null, 16, [
                                "class",
                                "icon",
                                "icon-cdn-src",
                                "is-promotions-highlighted",
                                "is-landing-euro",
                                "onClick"
                            ]), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'header-navigation-button',
                                        id
                                    }
                                ]
                            ])
                        ], 2);
                    }), 128)),
                    _unref(dropList).length > 0 ? (_openBlock(), _createElementBlock("li", {
                        key: 0,
                        class: _normalizeClass([
                            _ctx.$style['horizontal-navigation__list-item'],
                            _ctx.$style['horizontal-navigation__list-item--dropdown']
                        ])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['horizontal-navigation__more']),
                            onMouseover: _cache[0] || (_cache[0] = ($event)=>emit('set-parent-overflow-visible', true)),
                            onMouseleave: _cache[1] || (_cache[1] = ($event)=>emit('set-parent-overflow-visible', false))
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['horizontal-navigation__dots'])
                            }, null, 2),
                            _createElementVNode("ul", {
                                class: _normalizeClass(_ctx.$style['horizontal-navigation__drop'])
                            }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dropList), (param)=>{
                                    let { id, routeName, props, icon, iconCdnSrc, ...buttonProps } = param;
                                    return _openBlock(), _createElementBlock("li", {
                                        key: id,
                                        class: _normalizeClass(_ctx.$style['horizontal-navigation__list-item'])
                                    }, [
                                        _withDirectives(_createVNode(HeaderNavigationButton, _mergeProps({
                                            ref_for: true
                                        }, buttonProps, {
                                            class: id,
                                            inverted: "",
                                            icon: icon,
                                            "icon-cdn-src": iconCdnSrc,
                                            "is-promotions-highlighted": !!(props && props.isPromo && _ctx.isPromotionsHighlighted),
                                            "is-landing-euro": !!(props && props.isLandingEuro && _ctx.isLandingFestivalEnabled),
                                            onClick: ($event)=>{
                                                _unref(handleClick)({
                                                    id,
                                                    routeName,
                                                    props
                                                });
                                                emit('set-parent-overflow-visible', false);
                                            }
                                        }), null, 16, [
                                            "class",
                                            "icon",
                                            "icon-cdn-src",
                                            "is-promotions-highlighted",
                                            "is-landing-euro",
                                            "onClick"
                                        ]), [
                                            [
                                                _directive_data_test,
                                                {
                                                    el: 'header-navigation-button',
                                                    id
                                                }
                                            ]
                                        ])
                                    ], 2);
                                }), 128))
                            ], 2)
                        ], 34)
                    ], 2)) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'HeaderNavigation'
                ]
            ]);
        };
    }
});
