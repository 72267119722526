// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .header-notification-button_gKrW7{position:relative;display:flex;align-items:center;justify-content:center}html.app__layout--desktop .header-notification-button__icon_rQ2lu{display:flex;align-items:center;justify-content:center;width:40px;height:40px;color:var(--ColorContent1a);background-color:var(--ColorBackground1);border-radius:64px}html.app__layout--desktop .header-notification-button__icon_rQ2lu:hover{color:var(--ColorContent1)}html.app__layout--desktop .header-notification-button__badge_pyEnG{position:absolute;top:-4px;right:-4px}html.app__layout--desktop .header-notification-button__indicator_EJEuw{position:absolute;top:8px;right:8px;width:5px;height:5px;background-color:var(--AlertDefault);border-radius:50%}html.app__layout--desktop .header-notification-button__indicator--danger_KFUnA{background-color:var(--ErrorDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-notification-button": `header-notification-button_gKrW7`,
	"header-notification-button__icon": `header-notification-button__icon_rQ2lu`,
	"header-notification-button__badge": `header-notification-button__badge_pyEnG`,
	"header-notification-button__indicator": `header-notification-button__indicator_EJEuw`,
	"header-notification-button__indicator--danger": `header-notification-button__indicator--danger_KFUnA`
};
export default ___CSS_LOADER_EXPORT___;
