// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-switcher-button_Ya94c{display:flex;flex:none;align-items:center;justify-content:center;padding:0;line-height:inherit;color:var(--TextPrimary);background:none;border:none}@media(hover:hover)and (pointer:fine){.language-switcher-button_Ya94c:hover .language-switcher-button__visual_Ei6Ni{color:var(--TextDefault);cursor:pointer;background-color:var(--Highlight)}.language-switcher-button--is-header_B5LA9:hover .language-switcher-button__visual_Ei6Ni{color:var(--DTextDefault);background-color:var(--DHighlight)}}.language-switcher-button--touch-size_fC5C2{padding:16px 8px 8px}.language-switcher-button__visual_Ei6Ni{display:flex;align-items:center;height:36px;padding:0 4px 0 8px;background-color:var(--Button);border-radius:18px;transition:background-color .15s ease-in-out,color .15s ease-in-out}.language-switcher-button--no-background_NP5fl .language-switcher-button__visual_Ei6Ni{background-color:#0000}.language-switcher-button__icon_YV4XD{margin-right:2px}.language-switcher-button__language_sO7ri{font-size:12px;font-weight:500;line-height:16px;text-transform:uppercase;letter-spacing:.4px;margin-right:2px}.language-switcher-button--is-header_B5LA9{color:var(--DTextPrimary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"language-switcher-button": `language-switcher-button_Ya94c`,
	"language-switcher-button__visual": `language-switcher-button__visual_Ei6Ni`,
	"language-switcher-button--is-header": `language-switcher-button--is-header_B5LA9`,
	"language-switcher-button--touch-size": `language-switcher-button--touch-size_fC5C2`,
	"language-switcher-button--no-background": `language-switcher-button--no-background_NP5fl`,
	"language-switcher-button__icon": `language-switcher-button__icon_YV4XD`,
	"language-switcher-button__language": `language-switcher-button__language_sO7ri`
};
export default ___CSS_LOADER_EXPORT___;
