// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .header__logo_T8Uiq{display:flex;align-items:center;margin-right:16px}@media(min-width:1024px){html.app__layout--desktop .header__logo_T8Uiq{margin-right:16px}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header__logo": `header__logo_T8Uiq`
};
export default ___CSS_LOADER_EXPORT___;
