// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-switcher-button_c0NFp{display:flex;flex:none;align-items:center;justify-content:center;padding:0;line-height:inherit;color:var(--TextPrimary);background:none;border:none}@media(hover:hover)and (pointer:fine){.theme-switcher-button_c0NFp:hover .theme-switcher-button__visual_r25pA{color:var(--TextDefault);cursor:pointer;background-color:var(--Highlight)}.theme-switcher-button--is-header_oEfSW:hover .theme-switcher-button__visual_r25pA{color:var(--DTextDefault);background-color:var(--DHighlight)}}.theme-switcher-button__visual_r25pA{display:flex;gap:6px;align-items:center;height:36px;padding:0 10px;background-color:var(--Button);border-radius:18px;transition:background-color .15s ease-in-out,color .15s ease-in-out}.theme-switcher-button--no-background_Crkzz .theme-switcher-button__visual_r25pA{background-color:#0000}.theme-switcher-button__title_b71gX{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"theme-switcher-button": `theme-switcher-button_c0NFp`,
	"theme-switcher-button__visual": `theme-switcher-button__visual_r25pA`,
	"theme-switcher-button--is-header": `theme-switcher-button--is-header_oEfSW`,
	"theme-switcher-button--no-background": `theme-switcher-button--no-background_Crkzz`,
	"theme-switcher-button__title": `theme-switcher-button__title_b71gX`
};
export default ___CSS_LOADER_EXPORT___;
