// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .footer__menu-list_lFYLe{display:flex;flex-flow:column wrap;justify-content:flex-start;padding:0;margin:0;list-style:none}html.app__layout--desktop .footer__menu-list-item_baIK_{display:flex;padding:12px 0;color:var(--TextPrimary);font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px}html.app__layout--desktop .footer__menu-list-item_baIK_:hover{color:var(--TextDefault)}html.app__layout--desktop .footer__menu-list--simplified_Hp12G{display:flex;flex-wrap:wrap;row-gap:12px;column-gap:48px;justify-content:center}html.app__layout--desktop .footer__menu-list--simplified_Hp12G .footer__menu-list-item_baIK_{padding:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer__menu-list": `footer__menu-list_lFYLe`,
	"footer__menu-list-item": `footer__menu-list-item_baIK_`,
	"footer__menu-list--simplified": `footer__menu-list--simplified_Hp12G`
};
export default ___CSS_LOADER_EXPORT___;
