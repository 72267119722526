// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link_FPN8_{-webkit-text-decoration:none;text-decoration:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `link_FPN8_`
};
export default ___CSS_LOADER_EXPORT___;
