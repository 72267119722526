// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .divider_abTA1{width:100%;border-top:1px solid var(--Layer1)}html.app__layout--desktop .divider_abTA1:last-of-type{margin-bottom:24px}@media(max-width:699px){html.app__layout--desktop .divider--only-phone_K9XUV{display:none}}html.app__layout--desktop .sponsored-club__onwl{cursor:pointer}html.app__layout--desktop .footer-logos__list_F0y_c{display:flex;flex-wrap:wrap;align-items:center;justify-content:center;max-width:520px;padding:0;margin:0 auto;list-style:none}html.app__layout--desktop .footer-logos__list--tsupis_GkwIm{column-gap:32px;padding:24px}html.app__layout--desktop .footer-logos__list--base_opMA9{gap:16px 40px;padding:24px 0}html.app__layout--desktop .footer-logos__list--sponsored-clubs_Cb7Ct{row-gap:16px;column-gap:20px;padding:24px 0}html.app__layout--desktop .footer-logos__item_fOA_7{display:flex}html.app__layout--desktop .footer-logos__item_fOA_7 a{display:flex}html.app__layout--desktop .footer-logos__img--inversion_KIZH7{filter:var(--FooterIconFilter)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `divider_abTA1`,
	"divider--only-phone": `divider--only-phone_K9XUV`,
	"sponsored-club": `sponsored-club__onwl`,
	"footer-logos__list": `footer-logos__list_F0y_c`,
	"footer-logos__list--tsupis": `footer-logos__list--tsupis_GkwIm`,
	"footer-logos__list--base": `footer-logos__list--base_opMA9`,
	"footer-logos__list--sponsored-clubs": `footer-logos__list--sponsored-clubs_Cb7Ct`,
	"footer-logos__item": `footer-logos__item_fOA_7`,
	"footer-logos__img--inversion": `footer-logos__img--inversion_KIZH7`
};
export default ___CSS_LOADER_EXPORT___;
