// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.odds-switcher-button_B5znN{display:flex;flex:none;align-items:center;justify-content:center;padding:0;line-height:inherit;color:var(--TextPrimary);background:none;border:none}@media(hover:hover)and (pointer:fine){.odds-switcher-button_B5znN:hover .odds-switcher-button__visual_Vwo7O{color:var(--TextDefault);cursor:pointer;background-color:var(--Highlight)}.odds-switcher-button--is-header_HO3YD:hover .odds-switcher-button__visual_Vwo7O{color:var(--DTextDefault);background-color:var(--DHighlight)}}.odds-switcher-button__visual_Vwo7O{display:flex;gap:6px;align-items:center;height:36px;padding:0 10px;background-color:var(--Button);border-radius:18px;transition:background-color .15s ease-in-out,color .15s ease-in-out}.odds-switcher-button--no-background_idX3M .odds-switcher-button__visual_Vwo7O{background-color:#0000}.odds-switcher-button__title_b_wF_{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"odds-switcher-button": `odds-switcher-button_B5znN`,
	"odds-switcher-button__visual": `odds-switcher-button__visual_Vwo7O`,
	"odds-switcher-button--is-header": `odds-switcher-button--is-header_HO3YD`,
	"odds-switcher-button--no-background": `odds-switcher-button--no-background_idX3M`,
	"odds-switcher-button__title": `odds-switcher-button__title_b_wF_`
};
export default ___CSS_LOADER_EXPORT___;
