// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .footer__follow_QjZO5{padding-top:20px}html.app__layout--desktop .footer__follow-title_siQ9r{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;text-align:left}html.app__layout--desktop .footer__follow-list_ET05N{display:flex;flex-wrap:wrap;gap:8px;align-items:flex-start;align-self:stretch;justify-content:left;padding:8px 0 24px}html.app__layout--desktop .footer__follow-item_GzysJ{margin:0;overflow:hidden}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer__follow": `footer__follow_QjZO5`,
	"footer__follow-title": `footer__follow-title_siQ9r`,
	"footer__follow-list": `footer__follow-list_ET05N`,
	"footer__follow-item": `footer__follow-item_GzysJ`
};
export default ___CSS_LOADER_EXPORT___;
