// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-switcher_HQf5Q{display:flex;align-items:center;justify-content:center;width:100%;height:100%;cursor:pointer}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"theme-switcher": `theme-switcher_HQf5Q`
};
export default ___CSS_LOADER_EXPORT___;
