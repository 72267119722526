// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .header-bar_P_17U{width:100%;background-color:var(--DLayer1);box-shadow:none;height:56px}@media(min-width:1280px){html.app__layout--desktop .header-bar_P_17U{height:56px}}html.app__layout--desktop .header-bar__inner_DXKjm{display:flex;align-items:center;height:100%;justify-content:flex-start;min-width:700px;max-width:1440px;padding:0 16px;margin:0 auto}@media(min-width:1280px){html.app__layout--desktop .header-bar__inner_DXKjm{padding:0 16px}}html.app__layout--desktop .header-bar__left_KR3bx{flex:1;overflow:visible}html.app__layout--desktop .header-bar__left--overflow-hidden_UjAHj{overflow:hidden}html.app__layout--desktop .header-bar__left_KR3bx,html.app__layout--desktop .header-bar__right_LrMqc{display:flex;align-items:center}html.app__layout--desktop .header-bar__right_LrMqc{flex:none}html.app__layout--desktop .header-bar__menu-button_xY0a0{margin-right:8px}@media(min-width:1151px){html.app__layout--desktop .header-bar__menu-button_xY0a0{display:none}}.app__content--full-width_dM1kR html.app__layout--desktop .header-bar__menu-button_xY0a0{display:none}html.app__layout--desktop .header-bar__info_cZRce{position:relative;display:flex;align-items:center}html.app__layout--desktop .header-bar__lang_eBiJl{margin:0 8px}html.app__layout--desktop .header-bar__theme-switcher_kpHhB{padding-right:8px;padding-left:8px}html.app__layout--desktop .header-bar__search_wmAr6{margin:0 10px}html.app__layout--desktop .header-bar__search-btn_xep3K{display:none}@media(min-width:1024px){html.app__layout--desktop .header-bar__search-btn_xep3K{display:block}}@media(min-width:768px){html.app__layout--desktop .header-bar__registration_No3Hi{margin-left:16px}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-bar": `header-bar_P_17U`,
	"header-bar__inner": `header-bar__inner_DXKjm`,
	"header-bar__left": `header-bar__left_KR3bx`,
	"header-bar__left--overflow-hidden": `header-bar__left--overflow-hidden_UjAHj`,
	"header-bar__right": `header-bar__right_LrMqc`,
	"header-bar__menu-button": `header-bar__menu-button_xY0a0`,
	"app__content--full-width": `app__content--full-width_dM1kR`,
	"header-bar__info": `header-bar__info_cZRce`,
	"header-bar__lang": `header-bar__lang_eBiJl`,
	"header-bar__theme-switcher": `header-bar__theme-switcher_kpHhB`,
	"header-bar__search": `header-bar__search_wmAr6`,
	"header-bar__search-btn": `header-bar__search-btn_xep3K`,
	"header-bar__registration": `header-bar__registration_No3Hi`
};
export default ___CSS_LOADER_EXPORT___;
